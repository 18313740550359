.App {
  text-align: center;
}

.main-buck-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 6vh;
}

.main-buck-container h1 {
  font-size: 3rem;
}

.main-buck-container img {
  height: 40vw;
  border-radius: 100px;
}


  /* Gettin smaller! */
@media (max-width: 900px) {
  .main-buck-container img {
    height: 100vw;
  }
}
